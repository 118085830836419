import './bootstrap'
import '../css/app.css'

import {createApp, h} from 'vue'
import {createInertiaApp} from '@inertiajs/vue3'
import * as amplitude from '@amplitude/analytics-browser'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers'
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {ClickOutside} from '@/Directives/ClickOutside'
import {router} from '@inertiajs/vue3'
import Vue3Marquee from 'vue3-marquee'
import {createPinia} from 'pinia'
import axiosInstance from '@/Utils/axiosInstance'
const appName = import.meta.env.VITE_APP_NAME || 'Laravel'

import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import * as Sentry from '@sentry/vue'

amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, null, {
    apiEndpoint: '/amplitude',
})

const vuetify = createVuetify({
    components,
    directives,
})

const pinia = createPinia()

createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue')
        ),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(ZiggyVue)
            .use(AOS)
            .use(Vue3Marquee)
            .use(vuetify)
            .use(pinia)
            .directive('click-outside', ClickOutside)
            .mixin({
                computed: {
                    UTMSource() {
                        return localStorage.getItem('utm_source')
                    },
                    isSubscriptionActive() {
                        return this.$page.props.auth?.hasActiveSubscription
                    },
                },
            })
        app.config.globalProperties.$axios = axiosInstance

        const isStagingOrProduction =
            import.meta.env.VITE_APP_ENV === 'staging' ||
            import.meta.env.VITE_APP_ENV === 'production'

        if (isStagingOrProduction) {
            Sentry.init({
                app,
                dsn: 'https://2381bf67b8025286770b9a5e6e0a9154@o4506082179088384.ingest.us.sentry.io/4508126601740288',
                ignoreErrors: [/TypeError/, /Network Error/, /AxiosError/],
                beforeSend(event) {
                    if (event.exception && event.exception.values) {
                        const shouldIgnore = event.exception.values.some(
                            (exception) => {
                                const isTypeError =
                                    exception.type === 'TypeError'
                                const isNetworkError =
                                    exception.value &&
                                    exception.value.includes('Network Error')

                                return isTypeError || isNetworkError
                            }
                        )

                        if (shouldIgnore) {
                            return null
                        }
                    }
                    return event
                },
                ignoreUrls: [/.*node_modules.*/, /.*public\/build.*/],
                integrations: [
                    Sentry.browserTracingIntegration(),
                    // Sentry.replayIntegration(),
                ],
                tracesSampleRate: 1.0,
                tracePropagationTargets: [
                    'localhost',
                    new RegExp(`^${import.meta.env.VITE_APP_URL}`),
                ],
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
                environment: import.meta.env.VITE_APP_ENV,
            })
        }
        app.mount(el)
    },
    progress: {
        color: '#4B5563',
    },
})

router.on('navigate', (event) => {
    if (window.gtag) {
        let gtagConfig = {
            debug: import.meta.env.MODE !== 'production',
        }
        gtag('js', new Date())
        gtag('config', 'AW-11397947624', gtagConfig)
    }
})
